// Variables
@import "../variables";

// Colors
$primary-color: #5ec70d;
$black-color: #0a0002;
$white-color: #feecf0;

// Rest
@import "../frontend";
